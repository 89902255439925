// Core
import React, { useState } from 'react';

// Gatsby
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Img from 'gatsby-image';

// Hooks
import useForm from 'react-hook-form';
import useErrorMessage from '../hooks/useErrorMessage';

// Components
import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import Button from '../components/common/elements/button/MaterialButton';
import Input from '../components/common/elements/input/MBInput';
import Textarea from '../components/common/elements/textarea/MBTextarea';
import Select from '../components/common/elements/select/MBSelect';
import StateSelect from '../components/common/elements/select/MBStateSelect';
import Checkbox from '../components/common/elements/checkbox/Checkbox';
import Radio from '../components/common/elements/radio/Radio';
import { Table } from '../styles/tables';
import { RHFInput } from 'react-hook-form-input';
import DatePicker from 'react-datepicker';

// Redux actions
import { growl } from '../redux/actions/notifications/actionCreators';

// Styles
import { ThemeProvider } from 'styled-components';

import {
  Wrapper,
  HeroImageContentWrapper,
} from '../styles/pages/employment-application';

// Utilities
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import { service } from '../util/service';

// Config
import theme from '../styles/theme';

export const query = graphql`
  query EmploymentApplicationQuery {
    hero: file(relativePath: { regex: "/deluxe-clean-hero-image/" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function hero(data) {
  if (!data) {
    return <div />;
  }
  return (
    <HeroImage
      backdrop={false}
      marginTop="80px"
      image={<Img fluid={data.hero.childImageSharp.fluid} alt="" />}
    >
      <HeroImageContentWrapper>
        <h1>Employment Application</h1>
        <p>Fill out the application below.</p>
      </HeroImageContentWrapper>
    </HeroImage>
  );
}

function EmploymentApplication(props) {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [showAddPhoneNumberForm, setShowAddPhoneNumberForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    watch,
  } = useForm();

  const errorMessage = useErrorMessage(errors);

  async function onSubmit(data) {
    setIsSubmitting(true);
    const formData = pick(data, [
      'firstName',
      'middleName',
      'lastName',
      'emailAddress',
      'firstDayAvailable',
      'availableAnyTime',
      'highSchoolGedOrDiploma',
      'currentlyInCollege',
      'graduatedCollege',
      'fullTimeOrPartTimePreferred',
      'authorizedToWork',
      'convictedOfFelony',
      'felonyExplanation',
      'cleanDrivingRecord',
      'educationHistory',
      'skills',
      'references',
    ]);
    formData.address = pick(data, [
      'streetAddress',
      'streetAddressTwo',
      'city',
      'state',
      'zipCode',
    ]);
    formData.phoneNumbers = phoneNumbers;
    formData.daysAvailable = {
      monday: data.availableMonday,
      tuesday: data.availableTuesday,
      wednesday: data.availableWednesday,
      thursday: data.availableThursday,
      friday: data.availableFriday,
      saturday: data.availableSaturday,
      sunday: data.availableSunday,
    };

    try {
      const result = await service('employment-applications').create(formData);
      if (result && result.status === 200) {
        props.growl({
          message: 'Your application has been submitted',
          type: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      growl({
        message:
          'There was a problem submitting your application.  Please try again.',
        type: 'danger',
      });
      setIsSubmitting(false);
    }
  }

  watch();

  return (
    <ThemeProvider theme={theme}>
      <Layout hero={hero(props.data)}>
        <Wrapper>
          <div className="form__container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <p>Start by telling us your full name.</p>
              <Input
                type="text"
                name="firstName"
                label="First name"
                ref={register({ required: true })}
              />
              {errorMessage('firstName')}
              <Input
                type="text"
                name="middleName"
                label="Middle name"
                ref={register({ required: true })}
              />
              {errorMessage('middleName')}
              <Input
                type="text"
                name="lastName"
                label="Last name"
                ref={register({ required: true })}
              />
              {errorMessage('lastName')}
              <p>Now we need to know your physical address.</p>
              <Input
                type="text"
                name="streetAddress"
                label="Street address"
                ref={register({ required: true })}
              />
              {errorMessage('streetAddress')}
              <Input
                type="text"
                name="streetAddressTwo"
                label="Street address (Line 2)"
                ref={register}
              />
              {errorMessage('streetAddressTwo')}
              <Input
                type="text"
                name="city"
                label="City"
                ref={register({ required: true })}
              />
              {errorMessage('city')}
              <StateSelect
                name="state"
                label="Select a state"
                placeholder="Select a state"
                ref={register({ required: true })}
              />
              {errorMessage('state')}
              <Input
                type="text"
                name="zipCode"
                label="Zip code"
                ref={register({ required: true })}
              />
              {errorMessage('zipCode')}
              <div className="phone-number-section">
                <h2
                  style={{
                    color: '#ECC94B',
                    fontSize: '18px',
                    marginBottom: '12px',
                  }}
                >
                  Phone numbers
                </h2>
                <p>Please provide at least one phone number below.</p>
                {!showAddPhoneNumberForm && (
                  <Button
                    bgcolor="#ECC94B"
                    style={{ display: 'block', margin: '12px auto' }}
                    onClick={() => setShowAddPhoneNumberForm(true)}
                  >
                    Add phone number
                  </Button>
                )}
                {phoneNumbers.length > 0 && (
                  <Table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {phoneNumbers.map(phoneNumber => (
                        <tr key={phoneNumber.number}>
                          <td>{capitalize(phoneNumber.numberType)}</td>
                          <td>{phoneNumber.number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {showAddPhoneNumberForm && (
                  <div style={{ marginTop: '24px' }}>
                    <Select
                      id="form__phone-number-type"
                      label="Phone number type"
                      bgcolor="#FFFFF0"
                      options={[
                        { value: 'main', text: 'Main' },
                        { value: 'mobile', text: 'Mobile' },
                        { value: 'home', text: 'Home' },
                        { value: 'work', text: 'Work' },
                        { value: 'other', text: 'Other' },
                      ]}
                    />
                    <Input
                      id="form__phone-number"
                      label="Phone number"
                      type="text"
                    />
                    <div>
                      <Button
                        onClick={() => {
                          const newEntry = {
                            numberType: document.querySelector(
                              '#form__phone-number-type'
                            ).value,
                            number: document.querySelector(
                              '#form__phone-number'
                            ).value,
                          };
                          setPhoneNumbers(numbers => [...numbers, newEntry]);
                          setShowAddPhoneNumberForm(false);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Input
                label="Email address"
                type="email"
                name="emailAddress"
                ref={register}
              />
              {errorMessage('email')}
              <div>
                <p>When are you available to start?</p>
                {DatePicker && typeof window !== 'undefined' && (
                  <RHFInput
                    as={
                      <DatePicker
                        inline
                        selected={watch('firstDayAvailable', new Date())}
                        onChange={date => setValue('firstDayAvailable', date)}
                      />
                    }
                    name="firstDayAvailable"
                    register={register}
                    setValue={setValue}
                  />
                )}
                {errorMessage('firstDayAvailable')}
              </div>
              <div>
                <p>Do you have open availability throughout the day?</p>
                <Radio
                  name="availableAnyTime"
                  ref={register}
                  label="Yes"
                  value={true}
                />
                <Radio
                  name="availableAnyTime"
                  ref={register}
                  label="No"
                  value={false}
                />
                {errorMessage('availableAnyTime')}
                <div>
                  <p>What days are you available to work?</p>
                  <Checkbox
                    name="availableMonday"
                    ref={register}
                    label="Monday"
                  />
                  {errorMessage('availableMonday')}
                  <Checkbox
                    name="availableTuesday"
                    ref={register}
                    label="Tuesday"
                  />
                  {errorMessage('availableTuesday')}
                  <Checkbox
                    name="availableWednesday"
                    ref={register}
                    label="Wednesday"
                  />
                  {errorMessage('availableWednesday')}
                  <Checkbox
                    name="availableThursday"
                    ref={register}
                    label="Thursday"
                  />
                  {errorMessage('availableThursday')}
                  <Checkbox
                    name="availableFriday"
                    ref={register}
                    label="Friday"
                  />
                  {errorMessage('availableFriday')}
                  <Checkbox
                    name="availableSaturday"
                    ref={register}
                    label="Saturday"
                  />
                  {errorMessage('availableSaturday')}
                  <Checkbox
                    name="availableSunday"
                    ref={register}
                    label="Sunday"
                  />
                  {errorMessage('availableSunday')}
                </div>
              </div>
              <div>
                <p>Do you have a high school diploma or GED?</p>
                <Radio
                  name="highSchoolGedOrDiploma"
                  ref={register}
                  label="Yes"
                  value={true}
                />
                <Radio
                  name="highSchoolGedOrDiploma"
                  ref={register}
                  label="No"
                  value={false}
                />
              </div>
              {errorMessage('highSchoolGedOrDiploma')}
              <div>
                <p>Are you currently in college?</p>
                <Radio
                  name="currentlyInCollege"
                  ref={register}
                  value={true}
                  label="Yes"
                />
                <Radio
                  name="currentlyInCollege"
                  ref={register}
                  value={false}
                  label="No"
                />
              </div>
              {errorMessage('currentlyInCollege')}
              <div>
                <p>Have you graduated college?</p>
                <Radio
                  name="graduatedCollege"
                  ref={register}
                  value={true}
                  label="Yes"
                />
                <Radio
                  name="graduatedCollege"
                  ref={register}
                  value={false}
                  label="No"
                />
              </div>
              {errorMessage('graduatedCollege')}
              <div>
                <p>
                  Would your prefer full-time employment or part-time
                  employment?
                </p>
                <Radio
                  name="fullTimeOrPartTimePreferred"
                  ref={register}
                  value="full"
                  label="Full time"
                />
                <Radio
                  name="fullTimeOrPartTimePreferred"
                  ref={register}
                  value="part"
                  label="Part time"
                />
              </div>
              {errorMessage('fullTimeOrPartTimePreferred')}
              <div
                style={{
                  background: '#FFFAF0',
                  borderLeft: '6px solid #F6AD55',
                  padding: '24px',
                  margin: '24px 0',
                }}
              >
                <div>
                  <Checkbox
                    ref={register({
                      required: {
                        value: true,
                        message:
                          'You must be authorized to work in the United States to work for us.',
                      },
                    })}
                    name="authorizedToWork"
                    value={true}
                    label="I certify that I am authorized to work in the United States."
                  />
                </div>
              </div>
              {errorMessage('authorizedToWork')}
              <div>
                <p>Have you ever been convicted of a felony?</p>
                <Radio
                  name="convictedOfFelony"
                  ref={register}
                  value={true}
                  label="Yes"
                />
                <Radio
                  name="convictedOfFelony"
                  ref={register}
                  value={false}
                  label="No"
                />
              </div>
              {errorMessage('convictedOfFelony')}
              {getValues().convictedOfFelony === true && (
                <div style={{ marginTop: '24px' }}>
                  <Textarea
                    name="felonyExplanation"
                    label="Explain the felony conviction"
                    ref={register}
                  />
                  {errorMessage('felonyExplanation')}
                </div>
              )}
              <div>
                <p>Do you have a clean driving record?</p>
                <Radio
                  name="cleanDrivingRecord"
                  ref={register}
                  value={true}
                  label="Yes"
                />
                <Radio
                  name="cleanDrivingRecord"
                  ref={register}
                  value={false}
                  label="No"
                />
              </div>
              {errorMessage('cleanDrivingRecord')}
              <div style={{ marginTop: '24px' }}>
                <Textarea
                  name="educationHistory"
                  label="Please describe your education history"
                  ref={register}
                />
                {errorMessage('educationHistory')}
              </div>
              <div style={{ marginTop: '24px' }}>
                <Textarea
                  name="skills"
                  label="Please tell us about your skills"
                  ref={register}
                />
                {errorMessage('skills')}
              </div>
              <div style={{ marginTop: '24px' }}>
                <label>
                  <Textarea
                    name="references"
                    label="Please tell us any references you'd like to share"
                    ref={register}
                  />
                  {errorMessage('references')}
                </label>
              </div>
              <Button type="submit" disabled={isSubmitting}>
                Apply for employment
              </Button>
            </form>
          </div>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ message, type }) => dispatch(growl({ message, type })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentApplication);
