import React from 'react';

import Styled from 'styled-components';

export default React.forwardRef(function Radio(props, ref) {
  return (
    <Label>
      <span>{props.label}</span>
      <input {...props} type="radio" ref={ref} />
      <span className="radiobutton"></span>
    </Label>
  );
});

const Label = Styled.label`
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input ~ .radiobutton {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  &:hover input ~ .radiobutton {
    background-color: #ccc;
  }

  input:checked ~ .radiobutton {
    background-color: #2196F3;
  }

  .radiobutton:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    content: "";
    position: absolute;
  }

  input:checked ~ .radiobutton:after {
    display: block;
  }
`;
